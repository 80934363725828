

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-action-spin:hover {
    animation: App-spin infinite 5s linear;
  }
  .App-action-shake:hover {
    animation: App-shake 0.5s;
    animation-iteration-count: infinite;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



.App-toolbar{
  position: absolute;
  right:50px
}

.Content-wrapper{
  padding-top: 70px;
  margin-left: 50px;
  margin-right: 50px;
  display: grid;
 grid-template-columns: minmax(190px, 15%) 1fr;
 grid-gap: 20px;

  grid-template-areas:
    'header header'
    'menu main'
    'menu footer'
    ;
  /*flex-grow: 1;*/
  min-height: 100vh;
  min-width: calc(100% - 100px);

  overflow: auto;

}

@media only screen and (max-width: 958px) {
  .Content-wrapper{
    grid-template-areas:
      'header header'
      'main main'
      'footer footer'
      ;
      min-width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 20px;
  }
}


@media only screen and (max-width: 600px) {
  .Content-wrapper{
      min-width: calc(100% - 10px);
      margin-left: 5px;
      margin-right: 5px;
  }
}


@keyframes App-shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes App-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
